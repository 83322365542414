import { CLIENT_TYPE } from 'common/constants';
import { QuerySupportedDeliveryZoneArgs, DeliveryZone } from '../../types/generated/deliveryzones_ms_index';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { checkSupportedZoneQuery } from '../../queries/deliveryZones';

export type UseCheckSupportedZoneType = (
  input: QuerySupportedDeliveryZoneArgs,
) => QueryHookResponse<{ supportedDeliveryZone: DeliveryZone } | { supportedDeliveryZone: null }>;

const useCheckSupportedZone: UseCheckSupportedZoneType = input =>
  useQuery<{ supportedDeliveryZone: DeliveryZone } | { supportedDeliveryZone: null }>({
    query: checkSupportedZoneQuery,
    clientType: CLIENT_TYPE.DZMS_CLIENT,
    variables: input,
    handler: (res: any) => res,
    config: {},
  });

export default useCheckSupportedZone;
