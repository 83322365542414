import { useUpdateCart as useUpdateCartServiceHook } from 'service/hooks';
import { UpdateCartVarsType } from 'service/hooks/cart/types';
import { MutationUpdateCartArgs } from 'service/types';
import { useGlobalLoadingCart, useGlobalOrderTypeAndTime, usePersistedBranchId, usePersistedCartData } from 'common/hooks';
import { handleScheduledTime } from 'modules/productsModule/utils';

type UpdateCartCallbackArgumentsType = Omit<UpdateCartVarsType, 'scheduledTime' | 'cartId' | 'branchId'> & {
  branchId?: MutationUpdateCartArgs['branchId'];
};
type UseUpdateCartType = () => (variables: UpdateCartCallbackArgumentsType) => Promise<{ hasError: boolean }>;

const useUpdateCart: UseUpdateCartType = () => {
  const [cartData] = usePersistedCartData();
  const [persistedBranchId] = usePersistedBranchId();
  const [calculatedOrderTime] = useGlobalOrderTypeAndTime();
  const [, setLoadingCart] = useGlobalLoadingCart();

  const { fulfillmentTimeType, scheduleSlotInterval } = calculatedOrderTime || {};
  const scheduledTime = handleScheduledTime(fulfillmentTimeType, scheduleSlotInterval);

  const updateCart = useUpdateCartServiceHook({ ...cartData });

  return async ({ branchId, ...updateCartVariables }) => {
    setLoadingCart(true);
    const updateCartVars: UpdateCartVarsType = {
      ...(!!scheduledTime && { scheduledTime }),
      cartId: cartData?.cartId,
      branchId: branchId || persistedBranchId,
      ...updateCartVariables,
    };
    const response = await updateCart(updateCartVars);
    setLoadingCart(false);
    return response;
  };
};

export default useUpdateCart;
