import { Translate } from 'next-translate';
import { COUNTRY_CODES } from 'common/constants';

export const getAddressFields = (t: Translate, countryCode: string) => {
  const basicAddressInfo = {
    area: t('area'),
    ...(countryCode !== COUNTRY_CODES.KW && {
      city: t('city'),
      title: `${t('addressName')} ${t('optional')}`,
    }),
    ...(countryCode === COUNTRY_CODES.KW && {
      block: t('block'),
      avenue: `${t('avenue')} ${t('optional')}`,
    }),
    ...(countryCode === COUNTRY_CODES.BH && {
      block: t('block'),
    }),
    street: t('street'),
    phoneNumber: `${t('otherPhoneNumber')} ${t('optional')}`,
  };

  return {
    house: {
      ...basicAddressInfo,
      unitNo: t('houseNo'),
      notes: `${t('notes')} ${t('optional')}`,
    },
    apartment: {
      ...basicAddressInfo,
      building: t('building'),
      floor: t('floor'),
      unitNo: t('apartmentNo'),
      notes: `${t('notes')} ${t('optional')}`,
    },
    office: {
      ...basicAddressInfo,
      building: t('building'),
      floor: t('floor'),
      unitNo: t('officeNo'),
      notes: `${t('notes')} ${t('optional')}`,
    },
  };
};

export const formatDisplayedAddress = (area: string | undefined, governorate: string | undefined) => {
  const displayedArea = area || '';
  const displayedGovernorate = governorate || '';
  if (!displayedArea && !displayedGovernorate) return '';
  if (displayedArea && !displayedGovernorate) return displayedArea;
  if (!displayedArea && displayedGovernorate) return displayedGovernorate;
  return `${area}, ${governorate}`;
};
