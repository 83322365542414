import { gql } from 'graphql-request';

const fetchNearestZoneTemplate = gql`
  query ($geoPoint: [Float!]!) {
    nearestZoneTemplate(geoPoint: $geoPoint) {
      id
      areaTitleEn
      areaTitleAr
      cityTitleEn
      cityTitleAr
      countryTitleEn
      countryTitleAr
    }
  }
`;

export default fetchNearestZoneTemplate;
