import querystring from 'querystring';
import { isEmpty, dissoc } from 'ramda';
import * as Sentry from '@sentry/nextjs';

import STATUS from './Status';

type Payload = {
  body?: any;
  headers?: Record<string, string>;
  queryParams?: Record<string, string>;
  shouldCache?: boolean;
};

/**
 * Make the actual request
 * @param {string} url
 * @param {Object} reqObj
 * @param {boolean} shouldCache
 * @returns {Object}
 */
const fetchApi = async (url, reqObj, shouldCache) => {
  let response: Response | string = await fetch(url, reqObj);
  const statusCode = response.status;

  try {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      response = await response.json();
    } else {
      response = await response.text();
    }

    const returnResponse = {
      status: statusCode,
      // @ts-ignore
      data: response.Data ?? response.data,
      response,
    };

    if (shouldCache && STATUS.isSuccess(statusCode) && typeof window !== 'undefined') {
      window.localStorage.setItem(url, JSON.stringify(returnResponse));
      return returnResponse;
    }
    return returnResponse;
  } catch (error) {
    return Sentry.captureException(error, { extra: { error, url, response } });
  }
};

/**
 * General wrapper for fetch method
 * @private
 * @param {string} type
 * @param {string} url
 * @param {Object} [payload]
 * @param {Object} [payload.body]
 * @param {Object} [payload.headers = {}] headers map (key = header name, value = header value)
 * @param {Object} [payload.queryParams = {}]
 * @param {Boolean} [payload.shouldCache = false]
 *
 * @returns {Promise}
 */
const makeRequest = (type: string, url: string, payload: Payload) => {
  const { body, headers = {}, queryParams = {}, shouldCache = false } = payload || {};
  const requestHeaders = new Headers();

  // Set content type and append all passed headers to request headers
  if (type !== 'get') requestHeaders.append('Content-Type', 'application/json');
  Object.entries(headers).forEach(([key, value]) => requestHeaders.append(key, value));

  // Construct request object
  const reqObj = { method: type, headers: requestHeaders, body: undefined };
  if (body) reqObj.body = JSON.stringify(body);

  // Encode and use query params if provided
  let requestUrl;
  if (!isEmpty(queryParams)) {
    requestUrl = `${url}?${querystring.stringify(queryParams)}`;
  } else {
    requestUrl = url;
  }

  // Cache only if options is provided and the call is client side
  if (shouldCache && typeof window !== 'undefined') {
    // Check if the browser supports localStorage API
    if (window.localStorage) {
      const responseCache = window.localStorage.getItem(requestUrl);

      if (responseCache) {
        return new Promise(resolve => {
          resolve(JSON.parse(responseCache));
          fetchApi(requestUrl, reqObj, shouldCache);
        });
      }
    }
  }

  return fetchApi(requestUrl, reqObj, shouldCache);
};

const API = {
  /**
   * Perform a post request
   * @param {String} url
   * @param {Object} payload
   * @param {Object} [payload.body]
   * @param {Object} [payload.headers] headers map (key = header name, value = header value)
   * @param {Object} [payload.queryParams]
   * @param {Boolean} [payload.shouldCache]
   * @returns {Promise}
   */
  post(url: string, payload: Payload = {}): Promise<any> {
    return makeRequest('post', url, payload);
  },

  /**
   * Perform a put request
   * @param {String} url
   * @param {Object} [payload]
   * @param {Object} [payload.body]
   * @param {Object} [payload.headers] headers map (key = header name, value = header value)
   * @param {Object} [payload.queryParams]
   * @param {Boolean} [payload.shouldCache]
   * @returns {Promise}
   */
  put(url: string, payload: Payload = {}): Promise<any> {
    return makeRequest('put', url, payload);
  },

  /**
   * Perform a patch request
   * @param {String} url
   * @param {Object} [payload]
   * @param {Object} [payload.body]
   * @param {Object} [payload.headers] headers map (key = header name, value = header value)
   * @param {Object} [payload.queryParams]
   * @param {Boolean} [payload.shouldCache]
   * @returns {Promise}
   */
  patch(url: string, payload: Payload = {}): Promise<any> {
    return makeRequest('patch', url, payload);
  },

  /**
   * Perform a delete request
   * @param {String} url
   * @param {Object} [payload]
   * @param {Object} [payload.headers] headers map (key = header name, value = header value)
   * @param {Object} [payload.queryParams]
   * @param {Boolean} [payload.shouldCache]
   * @returns {Promise}
   */
  delete(url: string, payload: Payload = {}): Promise<any> {
    return makeRequest('delete', url, dissoc('body', payload));
  },

  /**
   * Perform a get request
   * @param {String} url
   * @param {Object} [payload]
   * @param {Object} [payload.headers] headers map (key = header name, value = header value)
   * @param {Object} [payload.queryParams]
   * @param {Boolean} [payload.shouldCache]
   * @returns {Promise}
   */
  get(url: string, payload: Payload = {}): Promise<any> {
    return makeRequest('get', url, dissoc('body', payload));
  },
};

export default API;
