import { apiMutate } from 'service/utils/mutation';
import ServiceConfigs from 'service/config';
import updateCartMutation from 'service/mutations/cart/updateCart';
import { useUpdateCartValues } from './utils';
import { UpdateCartVarsType } from './types';

type UseUpdateCartType = ({
  cartId,
  isCartAuthorized,
}: {
  cartId: string;
  isCartAuthorized?: boolean;
}) => (variables: UpdateCartVarsType) => Promise<{ hasError: boolean }>;

const useUpdateCart: UseUpdateCartType = ({ cartId, isCartAuthorized }) => {
  const updateCartValues = useUpdateCartValues({ cartId, isCartAuthorized });

  return variables => {
    if (!variables.cartId || !variables.branchId) return null;
    return apiMutate({
      mutation: updateCartMutation,
      variables: { ...variables, subdomain: ServiceConfigs.getSubDomain() },
      requestHeaders: { ...ServiceConfigs.getAuthorizationHeader() },
      handler: (res: any) => res?.updateCart,
    }).then(async cart => {
      if (cart) {
        await updateCartValues(cart);
      } else {
        await updateCartValues();
      }
      return { hasError: !cart };
    });
  };
};
export default useUpdateCart;
