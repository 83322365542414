import { CLIENT_TYPE } from 'common/constants';
import { QueryNearestZoneTemplateArgs, NearestZoneTemplate } from '../../types/generated/deliveryzones_ms_index';
import { apiMutate } from '../../utils/mutation';
import { fetchNearestZoneTemplate } from '../../queries/deliveryZones';

export type useFetchNearestZoneTemplateCallbackType = () => (
  input: QueryNearestZoneTemplateArgs,
) => Promise<{ nearestZoneTemplate: NearestZoneTemplate } | { nearestZoneTemplate: null }>;

const useFetchNearestZoneTemplateCallback: useFetchNearestZoneTemplateCallbackType = () => variables =>
  apiMutate({
    mutation: fetchNearestZoneTemplate,
    variables: { ...variables },
    clientType: CLIENT_TYPE.DZMS_CLIENT,
    handler: (res: any) => res,
  });

export default useFetchNearestZoneTemplateCallback;
