const Status = {
  // Getting access to status codes constants
  SUCCESS: 200,

  CREATED: 201,

  CONFLICT: 409,

  INVALID: 400,

  UNAUTHORIZED: 401,

  FORBIDDEN: 403,

  NOT_FOUND: 404,

  /**
   * Check if the api status code is 200 or 201
   * @param {number} statusCode api status code
   * @returns {boolean}
   */
  isSuccess(statusCode: number): boolean {
    const codes = [Status.SUCCESS, Status.CREATED];
    return codes.includes(statusCode);
  },

  /**
   * Check if the api status code is 409
   * @param {number} statusCode api status code
   * @returns {boolean}
   */
  isConflict(statusCode: number): boolean {
    return statusCode === Status.CONFLICT;
  },

  /**
   * Check if the api status code is 400
   * @param {number} statusCode api status code
   * @returns {boolean}
   */
  isInvalid(statusCode: number): boolean {
    return statusCode === Status.INVALID;
  },

  /**
   * Check if the api status code is 401
   * @param {number} statusCode api status code
   * @returns {boolean}
   */
  isUnauthorized(statusCode: number): boolean {
    return statusCode === Status.UNAUTHORIZED;
  },

  /**
   * Check if the api status code is 403
   * @param {number} statusCode api status code
   * @returns {boolean}
   */
  isForbidden(statusCode: number): boolean {
    return statusCode === Status.FORBIDDEN;
  },

  /**
   * Check if the api status code is 404
   * @param {number} statusCode api status code
   * @returns {boolean}
   */
  isNotFound(statusCode: number): boolean {
    return statusCode === Status.NOT_FOUND;
  },
};

export default Status;
