import config from '../../../common/config';
import API from '../../../common/utils/api/Api';
import { GetGeoLocationInfoType } from './types';

export const getGeoLocationInfo: GetGeoLocationInfoType = (lat, lng, languageCode, countryCode = '') => {
  const geoLocationServiceUrl = config.common.geolocationServiceUrl;
  return API.get(geoLocationServiceUrl, {
    queryParams: {
      lat,
      lng,
      country: countryCode,
    },
    headers: {
      'Accept-Language': languageCode,
    },
  });
};
